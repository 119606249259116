import React from "react";
import Helmet from "react-helmet"


export default () => (
	<Helmet>
		<title>COdA</title>
		<meta property="og:title" content="COdA"/>
		<meta name="description" content="fanzine, label and live arts collective est.2017"/>
		<meta property="og:type" content="website"/>
		<meta property="og:url" content="https://www.codafanzine.now.sh/"/>
		<meta property="og:title" content="COdA"></meta>
		<meta property="og:image" content="http://codafanzine.now.sh/images/home.jpg"/>
		<meta property="og:description" content="fanzine, label and live arts collective est. 2017"/>
		<meta name="msapplication-TileColor" content="#ffffff"/>
		<meta name="theme-color" content="#ffffff"/>
		<meta httpEquiv="Content-Security-Policy" content="base-uri 'self'; default-src 'self'; script-src 'self' 'unsafe-inline' data: www.google-analytics.com; style-src 'self' 'unsafe-inline' fonts.googleapis.com fonts.gstatic.com; object-src 'none'; form-action 'self'; font-src 'self' data: fonts.gstatic.com; connect-src 'self' https://us-central1-madcapcollective.cloudfunctions.net; img-src 'self' data: www.google-analytics.com; frame-src self https://bandcamp.com;"/>
		<link rel="apple-touch-icon" sizes="180x180" href="/favicon/apple-touch-icon.png"/>
		<link rel="icon" type="image/png" sizes="32x32" href="/favicon/favicon-32x32.png"/>
		<link rel="icon" type="image/png" sizes="16x16" href="/favicon/favicon-16x16.png"/>
		<link rel="manifest" href="/favicon/site.webmanifest"/>
		<link rel="mask-icon" href="/favicon/safari-pinned-tab.svg" color="#000000"/>
	</Helmet>
);
